import { Component, Input } from '@angular/core';
import { TableVirtualScrollDataSource } from 'ng-table-virtual-scroll';
import { DateTimeService } from 'src/app/services/date-time.service';
@Component({
    selector: 'app-data-table',
    templateUrl: './data-table.component.html',
    styleUrls: ['./data-table.component.scss'],
})
export class DataTableComponent {
    @Input() set data(data) {
        this.datasource = new TableVirtualScrollDataSource(data);
        this.includeMilliseconds = false;
        if (data) {
            const timestampsInSeconds = new Set(
                data.map((item) => Math.floor(item[0] / 1000)),
            );
            // Include milliseconds if they are needed for differentiation.
            this.includeMilliseconds = timestampsInSeconds.size != data.length;
        }
    }
    @Input() set labels(labels) {
        if (labels) {
            this.allLabels = ['time', ...labels];
        }
    }

    datasource;
    includeMilliseconds: boolean;
    allLabels: any[] = [];

    constructor(private dateTimeService: DateTimeService) {}

    get dateFormat(): string {
        // TODO: use the user's selected date & time formats
        return this.includeMilliseconds
            ? 'MM/dd/yyyy HH:mm:ss.SSS'
            : 'MM/dd/yyyy HH:mm:ss';
    }

    // Get the CSS class (if any) for a value.
    getClass(value): string {
        if (value === true) {
            return 'true';
        } else if (value === false) {
            return 'false';
        }
        return null;
    }

    // Get the icon (if any) for a value.
    // Returns null if there is no icon for the value.
    getIcon(value): string {
        if (value === true) {
            return 'check-bold';
        } else if (value === false) {
            return 'close-thick';
        } else if (value === Infinity) {
            return 'infinity';
        }
        return null;
    }

    getDateTimeString(value: Date): string {
        return this.dateTimeService.dateTimeString(value);
    }
}
