<div class="h-full">
    <cdk-virtual-scroll-viewport
        class="overflow-auto snap-proximity max-h-full h-screen"
        tvsItemSize="36"
    >
        <table mat-table [dataSource]="datasource" class="w-full">
            <tr mat-header-row *matHeaderRowDef="allLabels; sticky: true"></tr>
            <tr
                mat-row
                *matRowDef="let row; columns: allLabels"
                class="h-9"
            ></tr>

            <ng-container
                *ngFor="let col of allLabels; let i = index"
                [matColumnDef]="col"
            >
                <th
                    mat-header-cell
                    *matHeaderCellDef
                    class="!text-center !min-w-32 first-of-type:min-w-[200px] first-of-type:py-0 first-of-type:px-2 first-of-type:border-r-[1px] first-of-type:border-gray-300"
                >
                    {{ i === 0 ? ('label.time' | translate) : col }}
                </th>
                <td
                    mat-cell
                    class="first-of-type:min-w-[200px] first-of-type:py-0 first-of-type:px-2 first-of-type:border-r-[1px] first-of-type:border-gray-300 !text-center"
                    *matCellDef="let element"
                    [ngClass]="getClass(element[i])"
                >
                    <ng-container *ngIf="getIcon(element[i]); else textValue">
                        <mat-icon
                            [svgIcon]="getIcon(element[i])"
                            class="align-middle"
                        ></mat-icon>
                    </ng-container>
                    <ng-template #textValue>
                        <div class="align-middle leading-none">
                            {{
                                i === 0
                                    ? getDateTimeString(element[i])
                                    : element[i]
                            }}
                        </div>
                    </ng-template>
                </td>
            </ng-container>
        </table>
    </cdk-virtual-scroll-viewport>
</div>
